import { Body, Button, Display, theme } from '@moneylion/mlds-web'
import styled from 'styled-components'
import { useEffect, useMemo } from 'react'
import { DownloadAppData } from '@root/types/DownloadApp'
import { useViewport } from '@root/hooks'
import { ENV } from '@root/config'
import { segmentApi } from '@root/api'
import {
  ApplicationStore,
  HeroImage,
  Logo,
  QRCodeAndComparison,
} from './components'
import {
  appendTextBeforeExtension,
  getModifiedImageField,
  removeLineBreaks,
} from './utils'

const Container = styled.div`
  background-color: ${theme.colors['background-primary']};
  width: 100%;
  min-height: 100vh;
  padding: 40px 120px;

  .logo-header {
    margin-bottom: 40px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    padding: 24px 20px 75px 20px;

    .logo-header {
      text-align: center;
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 36px;

  .hero-copy {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    gap: 32px;
  }

  .qr-code-app-store-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    width: 100%;
  }

  .hero-image-wrapper {
    display: flex;
    flex-basis: 50%;
    align-items: center;
  }

  .cta {
    display: none;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    flex-direction: column-reverse;
    gap: 28px;

    .cta {
      display: block;
    }
  }

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    h1.display {
      font-size: 60px;
      line-height: 72px;
    }
  }
`

const DownloadAppPage = (props: { data: DownloadAppData }): JSX.Element => {
  const {
    description,
    disclaimer,
    image,
    qrCodeDetails,
    qrCodeImage,
    title,
    comparisonImageDesktop,
    comparisonImageMobile,
    oneLinkCode,
    oneLinkVariant,
  } = props.data

  const { width } = useViewport()

  const isMobileView = useMemo(() => width <= 600, [width])

  const withComparison = !!comparisonImageDesktop && !!comparisonImageMobile

  const shouldShowButton = withComparison && isMobileView

  const onClickButton = (): void => {
    const link = `${ENV.ONE_LINKS_BASE_URL}${oneLinkCode}`

    segmentApi.trackOnelink({
      onelink_variant: oneLinkVariant,
      onelink: link,
    })

    window.open(`${ENV.ONE_LINKS_BASE_URL}${oneLinkCode}`, '_self')
  }

  const getHeroImageSrc = (): string => {
    if (!image?.fields?.file?.fileName || !image?.fields?.file?.url) return ''

    if (ENV.CACHE_CTF_DOWNLOAD_APP_ASSETS === 'true' && ENV.NEXT_BUILD_ID) {
      return (
        '/_next/static/media/' +
        appendTextBeforeExtension({
          filename: image?.fields?.file?.fileName,
          text: ENV.NEXT_BUILD_ID,
        })
      )
    }

    return image?.fields?.file?.url
  }

  useEffect(() => {
    const redirectTreatment =
      new URLSearchParams(window.location.search).get('redirect') || undefined

    if (redirectTreatment) {
      segmentApi.trackWithOSPlatform('ob_migration_redirect_treatment', {
        treatment: redirectTreatment,
      })
    }
  }, [])

  return (
    <Container>
      <div className="logo-header">
        <Logo />
      </div>
      <ContentWrapper>
        <div className="hero-copy">
          <Display className="display" size="large" autoResize>
            {title}
          </Display>
          <Body size="xl" color="secondary">
            {description}
          </Body>
          {shouldShowButton && (
            <Button className="cta" onClick={onClickButton}>
              Download the app
            </Button>
          )}
          <div className="qr-code-app-store-wrapper">
            <QRCodeAndComparison
              qrCodeDetails={qrCodeDetails}
              qrCodeImage={getModifiedImageField({ imageField: qrCodeImage })}
              comparisonImageDesktop={getModifiedImageField({
                imageField: comparisonImageDesktop,
              })}
              comparisonImageMobile={getModifiedImageField({
                imageField: comparisonImageMobile,
              })}
              isMobileView={isMobileView}
            />
            <ApplicationStore info={props.data?.applicationStoreDetails} />
          </div>
          <div>
            <Body size="large" color="secondary" className="mb-3">
              MoneyLion is a leading mobile finance platform that empowers
              consumers to take control of their financial lives through better
              products for borrowing, saving and investing.
            </Body>
            {disclaimer &&
              removeLineBreaks(disclaimer).map((paragraph, index) => (
                <Body
                  size="small"
                  color="tertiary"
                  key={`disclaimer-${index}`}
                  className="mb-3"
                >
                  {paragraph}
                </Body>
              ))}
          </div>
        </div>
        <div className="hero-image-wrapper">
          <HeroImage src={getHeroImageSrc()} alt={title || ''} />
        </div>
      </ContentWrapper>
    </Container>
  )
}

export { DownloadAppPage }
