import { Card } from '@moneylion/mlds-web'
import Image from 'next/image'
import styled from 'styled-components'

const Container = styled(Card)`
  border-radius: 0px 0px 16px 16px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    border-radius: 16px;
  }
`

const ComparisonImage = styled(Image)`
  width: 100%;
  height: 100%;
`

interface ComparisonProps {
  src: string
  alt: string
}

const Comparison = ({ src, alt }: ComparisonProps): JSX.Element => (
  <Container>
    <ComparisonImage src={src} alt={alt} width={484} height={200} />
  </Container>
)

export { Comparison }
