import { Card } from '@moneylion/mlds-web'
import Image from 'next/image'
import Link from 'next/link'
import styled from 'styled-components'
import { DownloadAppData } from '@root/types/DownloadApp'
import { RatingStars } from '@root/components/DownloadMobileAppModal/RatingStars'
import AppStore from 'public/assets/svgs/app-store.svg'
import PlayStore from 'public/assets/svgs/play-store.svg'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 4px;

  .mobile-store-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px 32px;
    gap: 4px;
    width: 100%;
  }

  a.mobile-store-link {
    margin-bottom: 6px;

    img {
      width: 100%;
    }
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    .mobile-store-card {
      padding: 16px 12px;
    }

    a.mobile-store-link {
      margin-bottom: 0px;
    }
  }
`

interface ApplicationStoreProps {
  info: DownloadAppData['applicationStoreDetails']
}

const ApplicationStore = ({ info }: ApplicationStoreProps): JSX.Element => (
  <Container>
    <Card className="mobile-store-card">
      <Link
        href="https://itunes.apple.com/us/app/moneylion-personal-finance-credit-score-loans/id1064677082?mt=8"
        target="_blank"
        className="mobile-store-link"
      >
        <Image
          alt="MoneyLion on App Store"
          src={AppStore.src}
          width={211}
          height={60}
        />
      </Link>
      <RatingStars
        rating={parseFloat(info?.fields?.appleStoreStarRating || '0')}
      />
    </Card>
    <Card className="mobile-store-card">
      <Link
        href="https://play.google.com/store/apps/details?id=com.moneylion"
        target="_blank"
        className="mobile-store-link"
      >
        <Image
          alt="MoneyLion on Play Store"
          src={PlayStore.src}
          width={211}
          height={60}
        />
      </Link>
      <RatingStars
        rating={parseFloat(info?.fields?.googlePlayStoreStarRating || '0')}
      />
    </Card>
  </Container>
)

export { ApplicationStore }
