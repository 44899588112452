import Image from 'next/image'
import styled from 'styled-components'

const HeroImg = styled(Image)`
  width: 100%;
  height: auto;
  max-height: 70vh;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    max-height: 308px;
  }
`

interface HeroImageProps {
  src: string
  alt: string
}

const HeroImage = ({ src, alt }: HeroImageProps): JSX.Element => (
  <HeroImg src={src} alt={alt} width={466} height={600} priority />
)

export { HeroImage }
