import styled from 'styled-components'
import Image from 'next/image'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  gap: 4px;
  .rating-wrapper {
    display: flex;
    align-items: center;
  }
`

interface RatingStarsProps {
  rating: number
  size?: number
}

const RatingStars = ({ rating, size = 24 }: RatingStarsProps): JSX.Element => {
  const MAX_RATE = 5
  const isHalf = rating % 1 !== 0

  return (
    <Wrapper>
      <div className="rating-wrapper">
        {[...Array(MAX_RATE)].map((_, index) => {
          const current = index + 1
          if (current > Math.ceil(rating) && current > Math.floor(rating)) {
            return (
              <Image
                key={index}
                src="/assets/svgs/star-rating-empty.svg"
                width={size}
                height={size}
                alt="Rating star empty"
              />
            )
          }

          if (current > Math.floor(rating) && isHalf) {
            return (
              <Image
                key={index}
                src="/assets/svgs/star-rating-half.svg"
                width={size}
                height={size}
                alt="Rating star half"
              />
            )
          }

          return (
            <Image
              key={index}
              src="/assets/svgs/star-rating-filled.svg"
              width={size}
              height={size}
              alt="Rating star filled"
            />
          )
        })}
      </div>
    </Wrapper>
  )
}

export { RatingStars }
