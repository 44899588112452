import { ENV } from '@root/config'
import { ImageField } from '@root/types/DownloadApp'
import { appendTextBeforeExtension } from './appendTextBeforeExtension'

type PossiblyUndefinedImageField =
  | {
      fields?: ImageField
    }
  | undefined

interface GetModifiedImageFieldProps {
  imageField?: PossiblyUndefinedImageField
}

/**
 * @description Returns the image source for a QR code image
 * @param {PossiblyUndefinedImageField} { image }
 * @returns {PossiblyUndefinedImageField}
 * @example getQrImageSource({ image })
 **/
export const getModifiedImageField = ({
  imageField,
}: GetModifiedImageFieldProps): PossiblyUndefinedImageField => {
  if (!imageField?.fields?.file?.fileName || !imageField?.fields?.file?.url)
    return

  if (ENV.CACHE_CTF_DOWNLOAD_APP_ASSETS === 'true' && ENV.NEXT_BUILD_ID) {
    const modifiedFileName = appendTextBeforeExtension({
      filename: imageField.fields.file.fileName,
      text: ENV.NEXT_BUILD_ID,
    })

    return {
      fields: {
        ...imageField.fields,
        file: {
          fileName: modifiedFileName,
          url: `/_next/static/media/${modifiedFileName}`,
        },
      },
    }
  }

  return { fields: imageField.fields }
}
