import Image from 'next/image'
import MoneyLionDarkLogo from 'public/assets/svgs/ml-logo-dark.svg'

const Logo = (): JSX.Element => (
  <Image
    src={MoneyLionDarkLogo.src}
    alt="MoneyLion Logo"
    width={187}
    height={56}
  />
)

export { Logo }
