import { Body, Card, Heading } from '@moneylion/mlds-web'
import Image from 'next/image'
import styled, { css } from 'styled-components'
import { DownloadAppData } from '@root/types/DownloadApp'
import { Comparison } from './Comparison'

const Container = styled.div`
  width: 100%;
`

const QRCard = styled(Card)<{ withComparison?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px;
  gap: 32px;
  width: 100%;

  ${({ withComparison, theme }) =>
    withComparison &&
    css`
      border-bottom: ${theme.colors['border-primary']} 1px solid;
      border-radius: 16px 16px 0px 0px;
    `};

  .qr-code-details {
    font-size: 18px;
    line-height: 26px;
  }

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints?.tablet}) {
    padding: 24px 16px;

    .qr-code-title {
      font-size: 20px;
      line-height: 28px;
    }
  }
`

interface QRCodeProps {
  qrCodeDetails: DownloadAppData['qrCodeDetails']
  qrCodeImage: DownloadAppData['qrCodeImage']
  comparisonImageDesktop?: DownloadAppData['comparisonImageDesktop']
  comparisonImageMobile?: DownloadAppData['comparisonImageMobile']
  isMobileView?: boolean
}

const QRCodeAndComparison = ({
  qrCodeDetails,
  qrCodeImage,
  comparisonImageDesktop,
  comparisonImageMobile,
  isMobileView,
}: QRCodeProps): JSX.Element => {
  const withComparison = !!comparisonImageDesktop && !!comparisonImageMobile

  const shouldHideQRCard = withComparison && isMobileView

  return (
    <Container>
      {!shouldHideQRCard && (
        <QRCard withComparison={withComparison}>
          <Image
            src={qrCodeImage?.fields?.file?.url || ''}
            alt="QR Code"
            width={120}
            height={120}
          />
          <div>
            <Heading className="qr-code-title" autoResize>
              {qrCodeDetails?.fields?.title}
            </Heading>
            <Body size="large" color="secondary" className="qr-code-details">
              {qrCodeDetails?.fields?.description}
            </Body>
          </div>
        </QRCard>
      )}
      {withComparison && (
        <Comparison
          src={
            isMobileView
              ? comparisonImageMobile.fields?.file?.url || ''
              : comparisonImageDesktop.fields?.file?.url || ''
          }
          alt="Comparison"
        />
      )}
    </Container>
  )
}

export { QRCodeAndComparison }
