interface AppendTextBeforeExtensionProps {
  filename: string
  text: string
}

/**
 * Appends a hash ID to the filename before the file extension.
 * @param {string} filename - The original filename with extension.
 * @param {string} text - The text to append to the filename.
 * @returns {string} The new filename with the hash ID appended.
 */
export const appendTextBeforeExtension = ({
  filename,
  text,
}: AppendTextBeforeExtensionProps): string => {
  // Split the filename into name and extension
  const parts = filename.split('.')
  // Guard against filenames without an extension
  if (parts.length < 2) {
    console.error('Filename must include an extension.')
    return filename
  }
  // Remove the extension part
  const extension = parts.pop()
  // Join the remaining parts back together, in case there were multiple '.' in the filename
  const nameWithoutExtension = parts.join('.')
  // Return the new filename with the hashId appended before the extension
  return `${nameWithoutExtension}-${text}.${extension}`
}
